import { APPLICATIONS_SORT_CRITERIA, APPLICATIONS_TAB_LIST } from '../constants'
import { useApplicationsQueryParams } from './use-applications-query-params'
import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'
import { useFund } from '../../../shared/providers'
import { APPLICATIONS_PAGE_SIZE } from '@features/funds/constants'
import { useParams } from 'react-router-dom'
import { FundApplication } from '../types'

/**
 * Queries the backend for the list of applications based on the url parameter state
 */
export function useApplicationList() {
  const fund = useFund()
  const [applicationsQueryParams] = useApplicationsQueryParams()
  const { applicationId } = useParams<{ applicationId: string }>()

  const tab = APPLICATIONS_TAB_LIST(fund.options.type)[
    applicationsQueryParams.tab ?? 0
  ]
  const baseFilters = {
    fund_id: fund.id,
    solution_id: applicationsQueryParams.solutionId,
  }

  const filter = applicationId
    ? { ...baseFilters, id: applicationId }
    : {
        ...baseFilters,
        ...(applicationsQueryParams.q
          ? { keywords: applicationsQueryParams.q }
          : {}),
        status: tab.statusId,
        type: tab.type,
      }

  return useJsonApiQuery<FundApplication[]>([
    'applications',
    {
      filter,
      page: {
        size: APPLICATIONS_PAGE_SIZE,
        number: applicationsQueryParams.page,
      },
      stats: {
        total: 'count',
      },
      include: ['fund', 'owner', 'location', 'phases', 'solution', 'members'],
      'extra_fields[locations]': ['payments'],
      'extra_fields[solutions]': ['templates'],
      sort: applicationsQueryParams.sort ?? APPLICATIONS_SORT_CRITERIA[0].id,
    },
  ])
}
