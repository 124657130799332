import {
  PhaseStatus,
  TPhase,
  UserFundRole,
  TDueDefinition,
  DueDefinitionType,
} from '@features/funds/types'
import Step from './step'
import { useFund } from 'shared/providers'
import { Badge, ExpandableSection } from '@changex/design-system'
import { CheckIcon, LockClosedIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'

// TODO: [myf_phases] Cleanup when phase is implemented
const MYF_FAKED_PHASE_POSITION = 2

dayjs.extend(RelativeTime)

const StatusBadge = ({
  status,
  due,
}: {
  status: PhaseStatus
  due: TDueDefinition
}) => {
  switch (status) {
    case PhaseStatus.Complete:
      return (
        <Badge variant="success" className="inline-flex gap-1">
          <CheckIcon className="h-4 w-4 text-green-700" /> Completed
        </Badge>
      )
    case PhaseStatus.Incomplete:
      // Should always be a date range for incomplete phases but it's hard to enforce with types, so handling approx case silently
      return due.type === DueDefinitionType.DateRange ? (
        <Badge variant="orange">Ends in {dayjs().to(due.endDate, true)}</Badge>
      ) : null
    case PhaseStatus.Locked:
      if (due.type === DueDefinitionType.DateRange) {
        return (
          <Badge variant="basic" className="inline-flex gap-1">
            <LockClosedIcon className="h-4 w-4 text-gray-400" /> Unlocks in{' '}
            {dayjs().to(due.startDate, true)}
          </Badge>
        )
      } else {
        return (
          <Badge variant="basic" className="inline-flex gap-1">
            <LockClosedIcon className="h-4 w-4 text-gray-400" /> Locked
          </Badge>
        )
      }
  }
}

const Phase = ({
  locationId,
  phase,
}: {
  locationId: string
  phase: TPhase
}) => {
  const hasAdminRole = useFund().userRole === UserFundRole.Admin

  return (
    <ExpandableSection
      heading={
        <div className="flex w-full justify-between">
          <div className="text-base font-medium leading-normal text-gray-800">
            {phase.title}
          </div>
          <StatusBadge status={phase.status} due={phase.due} />
        </div>
      }
      defaultOpen={phase.status === PhaseStatus.Incomplete}
      className="space-y-4"
    >
      <ul className="grid auto-cols-fr grid-flow-col gap-2">
        {phase.steps.map((step) => (
          <Step
            key={step.id}
            step={step}
            locationId={locationId}
            showActions={
              hasAdminRole && phase.position < MYF_FAKED_PHASE_POSITION
            }
          />
        ))}
      </ul>
    </ExpandableSection>
  )
}

export default Phase
