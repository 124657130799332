import { httpClient } from '@api'

type TInput = {
  id: string
}

export function useLikePhotoMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.post('/likes', {
        data: {
          type: 'likes',
          relationships: {
            likeable: { data: { id: input.id, type: 'assets' } },
          },
        },
      })
    },
  }
}
