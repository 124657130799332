import {
  PieFinancialStats,
  formatCurrency,
  Tooltip,
  useHover,
  TCurrencyIsoCode,
} from '@changex/design-system'
import { utils } from 'features/funds/utils'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'

type TProps = {
  currency: TCurrencyIsoCode
  fundTotalAmount: number
  requestedFunds: { [key: string]: number }
  awardedFunds: { [key: string]: number }
  waitingFunds: { [key: string]: number }
}

const keyToLabel = {
  replication: 'Replications',
  openGrant: 'Open grants',
}

const PieChartForStats = ({
  label,
  tooltip,
  currency,
  stats,
  fundTotalAmount,
}: {
  tooltip: string
  label: string
  currency: TCurrencyIsoCode
  stats: { [key: string]: number }
  fundTotalAmount: number
}) => {
  const total = utils.sumValues(stats)
  const chartData = Object.keys(stats).map((key) => {
    const value = stats[key]
    const percentage = (value / total) * 100
    const label = keyToLabel[key]
    return {
      label,
      value,
      percentage,
      customTooltip: `<b>${label}</b> - ${percentage.toFixed(
        0
      )}% <br /> ${formatCurrency(value, currency)}`,
    }
  })
  return (
    <PieFinancialStats
      className="border-r py-8 px-6"
      tooltip={tooltip}
      label={label}
      currency={currency}
      amount={total}
      percentage={(total / fundTotalAmount) * 100}
      chartData={chartData}
    />
  )
}

const HybridStats = ({
  currency,
  fundTotalAmount,
  requestedFunds,
  awardedFunds,
  waitingFunds,
}: TProps) => {
  const [ref, isHovered] = useHover()
  return (
    <div className="flex rounded-md border">
      <PieChartForStats
        tooltip="The total amount of funding requested by applications to this fund, including the waitlist and unsuccessful applications"
        label="Total requested"
        currency={currency}
        stats={requestedFunds}
        fundTotalAmount={fundTotalAmount}
      />
      <PieChartForStats
        tooltip="Replication funding approved, open grant funding allocated and approved, and all funding paid out to applicants and social innovators"
        label="Awarded"
        currency={currency}
        stats={awardedFunds}
        fundTotalAmount={fundTotalAmount}
      />
      <div
        className="flex flex-1 flex-col gap-2 bg-gray-50 px-6 py-8"
        ref={ref}
      >
        <h1 className="flex items-center gap-2 text-lg text-gray-800">
          Waiting
          {isHovered && (
            <Tooltip>
              <Tooltip.Trigger>
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
              </Tooltip.Trigger>
              <Tooltip.Content>
                Amount requested by applications which are currently on the
                waitlist, or waiting to be reviewed in the open grants inbox
              </Tooltip.Content>
            </Tooltip>
          )}
        </h1>
        <span className="text-2xl font-medium text-teal-900">
          {formatCurrency(utils.sumValues(waitingFunds), currency)}
        </span>
        <div className="flex flex-col">
          <span className="text-sm text-gray-700">Open grants</span>
          <span className="text-gray-900">
            {formatCurrency(waitingFunds.openGrant, currency)}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-700">Replications</span>
          <span className="text-gray-900">
            {formatCurrency(waitingFunds.replication, currency)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default HybridStats
