import { useFormContext, Controller } from 'react-hook-form'
import { ExclamationIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import {
  Checkbox,
  Label,
  MoneyInput,
  Skeleton,
  TCurrency,
} from '@changex/design-system'
import { useJsonApiQuery } from 'shared/hooks/json-api/use-json-api-query'
import { SocialCategory } from '@features/funds/types'
import { useFund } from 'shared/providers'
import { NudgeEmailsEnabledSetting } from '@types'
import useSetting from 'shared/hooks/use-setting'

const useSocialCategoriesQuery = () => {
  return useJsonApiQuery<SocialCategory[]>([
    'social_categories',
    {
      page: { size: 50 },
    },
  ])
}

const SocialCategoriesSkeleton = () => {
  return (
    <div className="mt-4 flex h-[32rem] animate-pulse flex-col flex-wrap gap-2">
      {[...Array(30)].map((_, i) => (
        <div key={i} className="flex w-1/3">
          <div className="mb-2 mr-4 h-2 w-2 rounded-sm bg-gray-300"></div>
          <div
            className={classNames('h-2 w-full rounded bg-gray-300', {
              'w-full': i % 3 === 0,
              'w-3/4': i % 3 === 1,
              'w-4/5': i % 3 === 2,
            })}
          ></div>
        </div>
      ))}
    </div>
  )
}
const SocialCategories = () => {
  const { isLoading, data } = useSocialCategoriesQuery()
  const { control } = useFormContext()

  return (
    <div>
      <Label>Themes</Label>
      <p className="text-sm text-gray-500">
        Applicants must choose which theme their project relates to, so that we
        can ensure it is relevant to the fund. Select 3-6 options for them to
        choose from.
      </p>

      {isLoading ? (
        <SocialCategoriesSkeleton />
      ) : (
        <Controller
          name="options.openGrants.socialCategoriesOptions"
          control={control}
          defaultValue={[]}
          rules={{ required: true }}
          render={({
            field: { value, onChange, ref },
            fieldState: { error },
          }) => (
            <>
              {error && (
                <div className="my-2 text-red-600">
                  <ExclamationIcon className="mr-2 inline-block h-4 w-4" />
                  You need to select some themes!
                </div>
              )}
              <div className="mt-4 flex h-[32rem] flex-col flex-wrap gap-2">
                {data?.results
                  ?.sort((a, b) => (a.name > b.name ? 1 : 0))
                  .map((socialCategory) => (
                    <Checkbox
                      key={socialCategory.id}
                      value={socialCategory.id}
                      checked={value.includes(parseInt(socialCategory.id))}
                      onChange={(event) => {
                        const existingSelectedOptions = value
                        const targetValue = parseInt(event.target.value)
                        if (event.target.checked) {
                          onChange([...existingSelectedOptions, targetValue])
                        } else {
                          onChange(
                            existingSelectedOptions.filter(
                              (value: number) => value !== targetValue
                            )
                          )
                        }
                      }}
                      label={socialCategory.name}
                    />
                  ))}
              </div>
            </>
          )}
        />
      )}
    </div>
  )
}

const AmountsSkeleton = () => (
  <>
    <Skeleton className="h-[62px] w-[250px]" />
    <Skeleton className="h-[62px] w-[250px]" />
    <Skeleton className="h-[62px] w-[250px]" />
  </>
)

const Amounts = ({ currency }: Props) => {
  const { control } = useFormContext()

  return (
    <div>
      <Label>Amount options</Label>
      <p className="text-sm text-gray-500">
        Applicants can choose how much funding to apply for. Provide some
        choices below.
      </p>
      <div className="mt-4 flex gap-2">
        {currency ? (
          <>
            <Controller
              name="options.openGrants.budget.options.0"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, name },
                fieldState: { error },
              }) => (
                <MoneyInput
                  label="Option 1"
                  canChangeCurrency={false}
                  currency={currency}
                  amount={value}
                  amountElementId={name}
                  onAmountChange={(amount) => onChange(amount)}
                  error={!!error}
                />
              )}
            />
            <Controller
              name="options.openGrants.budget.options.1"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, name },
                fieldState: { error },
              }) => (
                <MoneyInput
                  label="Option 2"
                  canChangeCurrency={false}
                  currency={currency}
                  amount={value}
                  amountElementId={name}
                  onAmountChange={(amount) => onChange(amount)}
                  error={!!error}
                />
              )}
            />
            <Controller
              name="options.openGrants.budget.options.2"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange, name },
                fieldState: { error },
              }) => (
                <MoneyInput
                  label="Option 3"
                  canChangeCurrency={false}
                  currency={currency}
                  amount={value}
                  amountElementId={name}
                  onAmountChange={(amount) => onChange(amount)}
                  error={!!error}
                />
              )}
            />
          </>
        ) : (
          <AmountsSkeleton />
        )}
      </div>
    </div>
  )
}

const CustomAmountsSkeleton = () => (
  <>
    <Skeleton className="h-[62px] w-[250px]" />
    <Skeleton className="h-[62px] w-[250px]" />
  </>
)

const CustomAmounts = ({ currency }: Props) => {
  const { control } = useFormContext()

  return (
    <div className="flex items-center gap-4">
      {currency ? (
        <>
          <Controller
            name="options.openGrants.budget.min"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => (
              <MoneyInput
                label="Min grant"
                canChangeCurrency={false}
                currency={currency}
                amount={value}
                amountElementId={name}
                onAmountChange={(amount) => onChange(amount)}
                error={!!error}
              />
            )}
          />
          <span className="pt-4 text-gray-500">to</span>
          <Controller
            name="options.openGrants.budget.max"
            control={control}
            rules={{ required: true }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => (
              <MoneyInput
                label="Max grant"
                canChangeCurrency={false}
                currency={currency}
                amount={value}
                amountElementId={name}
                onAmountChange={(amount) => onChange(amount)}
                error={!!error}
              />
            )}
          />
        </>
      ) : (
        <CustomAmountsSkeleton />
      )}
    </div>
  )
}

const NudgeEmailsCheckbox = () => {
  const fundId = useFund().id

  const defaultSetting: NudgeEmailsEnabledSetting = {
    key: 'nudge_emails_enabled',
    settingableId: fundId,
    settingableType: 'Fund',
    settingType: 'boolean',
    value: { data: true },
  }

  const [enabled, setEnabled] = useSetting(defaultSetting)

  return (
    <Checkbox
      id="nudge-emails-checkbox"
      label="Send nudge emails"
      description="Automatically send reminder emails to applicants who haven't submitted their application."
      checked={enabled.data}
      onChange={() => {
        setEnabled({ data: !enabled.data })
      }}
    />
  )
}

type Props = {
  currency: TCurrency | undefined
}

const OpenGrantEditor = ({ currency }: Props) => {
  const { watch, control, register, unregister } = useFormContext()
  const shouldAllowCustomAmounts = watch('options.openGrants.budget.custom')

  return (
    <>
      <div className="flex flex-col gap-4">
        <SocialCategories />
        <Amounts currency={currency} />
        <input
          type="hidden"
          {...register('options.openGrants.solutionId')}
          value="99-open"
        />
        <input
          type="hidden"
          {...register('options.openGrants.type')}
          value="open"
        />
        <input
          type="hidden"
          {...register('options.openGrants.maxReplications', {
            valueAsNumber: true,
          })}
          defaultValue={5000} // Sets a default value for new funds, without overwritting existing values. We eventually want to remove the maxReplications option for open-grants so don't want to expose it
        />
        <NudgeEmailsCheckbox />
        <Controller
          control={control}
          name="options.openGrants.budget.custom"
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              label="Allow custom amounts"
              description="Gives applicants an input to type a specifc amount, if none of the pre-defined options match their needs. The pre-defined options will also be shown and still need to be provided."
              onChange={(event) => {
                const checked = event.target.checked
                onChange(checked)
                if (!checked) {
                  unregister([
                    'options.openGrants.budget.min',
                    'options.openGrants.budget.max',
                  ])
                }
              }}
              value={value.toString()}
              checked={value}
            />
          )}
        />
        {shouldAllowCustomAmounts && <CustomAmounts currency={currency} />}
      </div>
    </>
  )
}

export default OpenGrantEditor
