import { FundApplication } from '../../../types'
import { utils } from '@features/funds/utils'
import { useLocation } from 'react-router-dom'
import BaseApplicationOwner from '../../base-application/base-application-owner'

type TProps = {
  data: FundApplication
}

const ApplicationOwner = ({ data }: TProps) => {
  const { pathname } = useLocation()
  const applicationUrl = `${window.location.origin}${pathname}/${data.id}`

  const isOpenGrant = utils.isOpenGrantApplication(data)

  return (
    <BaseApplicationOwner avatarUrl={data?.owner?.avatarUrl || ''}>
      <BaseApplicationOwner.HeaderSection
        fullName={data?.owner?.fullName || ''}
        entityType={data?.entityType}
        isOpenGrant={isOpenGrant}
        applicationUrl={applicationUrl}
      />
      <BaseApplicationOwner.ContactDetails
        organisationName={data.organisationName}
        email={data?.owner?.email || ''}
        phone={isOpenGrant ? data.phone : data.owner?.phone}
        locationName={data.location.address}
      />
    </BaseApplicationOwner>
  )
}

export default ApplicationOwner
