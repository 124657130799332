import { Icon, Text, formatDateShort } from '@changex/design-system'
import { FundApplication, PhaseStatus, StepStatus } from 'features/funds/types'
import { getCurrentPhase, getCurrentStep } from 'shared/utils/phase-helpers'
import classNames from 'classnames'

type TProps = {
  data: FundApplication
}

const getDaysLeftStatus = (daysRemaining: number) => {
  if (daysRemaining === 0) {
    return 'light'
  }
  if (daysRemaining <= 7) {
    return 'error'
  }
  if (daysRemaining <= 14) {
    return 'warning'
  }
  return 'success-dark'
}

const ApplicationProgress = ({ data }: TProps) => {
  const { phases, challengeDaysRemaining } = data
  const steps = phases.flatMap((phase) => phase.steps)

  const isChallengeFinished =
    phases.length > 0 && phases[0].status === PhaseStatus.Complete
  const currentStep = getCurrentStep(getCurrentPhase(phases))

  return (
    <div className="flex-[0 1 325px]">
      <div className="flex items-center justify-between gap-x-10">
        <Text
          className="flex items-center"
          variant={
            isChallengeFinished
              ? 'success-dark'
              : getDaysLeftStatus(challengeDaysRemaining)
          }
          size="sm"
        >
          {isChallengeFinished ? (
            'Challenge completed'
          ) : challengeDaysRemaining > 0 ? (
            <>
              <Icon className="pr-1.5" icon="Clock" size="sm" />
              {challengeDaysRemaining}{' '}
              {challengeDaysRemaining === 1 ? `day` : `days`} left
            </>
          ) : (
            'Challenge overdue'
          )}
        </Text>
        <div>
          <span className="text-sm">
            Applied: {formatDateShort(new Date(data.submittedAt))}
          </span>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex w-full items-center gap-1" data-testid="progress">
          {steps.map((step) => (
            <span
              className={classNames(
                'grow',
                currentStep.id === step.id &&
                  step.status !== StepStatus.Complete
                  ? 'h-3'
                  : 'h-2',
                step.status === StepStatus.Complete
                  ? 'bg-green-500'
                  : 'bg-gray-200'
              )}
              data-testid="stage"
              key={step.id}
            />
          ))}
        </div>
      </div>
      <div className="mt-2">
        {currentStep && currentStep.status !== StepStatus.Complete && (
          <span className="text-sm capitalize">
            Step: {currentStep.stepType}
          </span>
        )}
      </div>
    </div>
  )
}

export default ApplicationProgress
