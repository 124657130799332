import { LoadingIcon, Tooltip } from '@changex/design-system'
import classNames from 'classnames'
import { useCallback } from 'react'
import { useMarkApplicationStepComplete } from '@features/funds/hooks/use-mark-application-step-as-complete'
import toast from 'react-hot-toast'
import { StepStatus, TPhaseStep } from '@features/funds/types'
import { CheckCircleIcon, LockClosedIcon } from '@heroicons/react/solid'

const MarkStepAsCompleteButton = ({
  onMarkStepAsComplete,
  step,
}: {
  onMarkStepAsComplete: () => void
  step: TPhaseStep
}) => {
  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <button
          className="group inline-flex items-center justify-start gap-1 self-stretch"
          onClick={onMarkStepAsComplete}
        >
          <StepStatusAndType step={step} interactive={true} />
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content>Mark step as completed</Tooltip.Content>
    </Tooltip>
  )
}

const StatusIcon = ({
  status,
  isLoading = false,
  interactive = false,
}: {
  status: StepStatus
  isLoading?: boolean
  interactive?: boolean
}) => {
  if (isLoading) {
    return (
      <div className="flex h-6 w-6 items-center justify-center">
        <LoadingIcon />
      </div>
    )
  }

  switch (status) {
    case StepStatus.Complete:
      return <CheckCircleIcon className="h-6 w-6 text-green-500" />
    case StepStatus.Locked:
      return (
        <div className="flex h-6 w-6 items-center justify-center">
          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-100">
            <LockClosedIcon className="h-4 w-4 text-gray-400" />
          </div>
        </div>
      )
    case StepStatus.Incomplete:
      return (
        <div className="flex h-6 w-6 items-center justify-center">
          <div
            className={classNames(
              'h-[18px] w-[18px] rounded-full border border-gray-400 group-hover:border-green-600',
              {
                'group-hover:border-green-600': interactive,
              }
            )}
          ></div>
        </div>
      )
  }
}

const StepStatusAndType = ({
  step,
  isLoading = false,
  interactive = false,
}: {
  step: TPhaseStep
  isLoading?: boolean
  interactive?: boolean
}) => {
  return (
    <>
      <span>
        <StatusIcon
          status={step.status}
          isLoading={isLoading}
          interactive={interactive}
        />
      </span>
      <span className="flex-1 text-sm capitalize leading-tight text-gray-800">
        {step.stepType}
      </span>
    </>
  )
}

function Step({
  step,
  locationId,
  showActions,
}: {
  step: TPhaseStep
  locationId: string
  showActions: boolean
}) {
  const completed = step.status === StepStatus.Complete
  const markStepAsCompleteMutation = useMarkApplicationStepComplete()
  const handleMarkStepAsComplete = useCallback(() => {
    markStepAsCompleteMutation.mutate(
      {
        onboardingStepId: step.id.toString(),
        locationId: locationId,
      },
      {
        onSuccess: () => {
          toast.success(`Marked step as complete`)
        },
        onError: () => {
          toast.error('There was an error updating the step')
        },
      }
    )
  }, [locationId, markStepAsCompleteMutation, step.id])
  const isLoading = markStepAsCompleteMutation.isLoading

  return (
    <li
      className={classNames('border-t-4 pt-3', {
        'border-green-500': completed,
        'border-gray-200': !completed,
      })}
    >
      {showActions && step.status === StepStatus.Incomplete && !isLoading ? (
        <MarkStepAsCompleteButton
          onMarkStepAsComplete={handleMarkStepAsComplete}
          step={step}
        />
      ) : (
        <div className="inline-flex items-center justify-start gap-1 self-stretch">
          <StepStatusAndType step={step} isLoading={isLoading} />
        </div>
      )}
      <div className="text-xs text-gray-600">{step.title}</div>
    </li>
  )
}

export default Step
