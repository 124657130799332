import { FundApplication } from '@features/funds/types'
import { ApplicationApprovalStageInbox } from '@features/funds/components/applications-list/application/approval/application-approval-stage-inbox'
import { ApplicationApprovalStageAllocated } from '@features/funds/components/applications-list/application/approval/application-approval-stage-allocated'
import { ApplicationApprovalStage } from '@features/funds/components/applications-list/application/approval/application-approval.types'
import { ApplicationApprovalStageSucceeded } from '@features/funds/components/applications-list/application/approval/application-approval-stage-succeeded'
import { ApplicationApprovalStageApproved } from '@features/funds/components/applications-list/application/approval/application-approval-stage-approved'
import { ApplicationApprovalStagePaidSeed } from '@features/funds/components/applications-list/application/approval/application-approval-stage-paid-seed'
import { ApplicationApprovalStageImpact } from '@features/funds/components/applications-list/application/approval/application-approval-stage-impact'
import { ApplicationApprovalStagePaidImpact } from '@features/funds/components/applications-list/application/approval/application-approval-stage-paid-impact'

type Props = {
  application: FundApplication
  stage: ApplicationApprovalStage
}

const StageComponent = ({
  stage,
  application,
}: {
  stage: ApplicationApprovalStage
  application: FundApplication
}) => {
  switch (stage) {
    case 'inbox':
      return <ApplicationApprovalStageInbox application={application} />
    case 'allocated':
      return <ApplicationApprovalStageAllocated application={application} />
    case 'succeeded':
      return <ApplicationApprovalStageSucceeded application={application} />
    case 'approved':
      return <ApplicationApprovalStageApproved application={application} />
    case 'paidSeed':
      return <ApplicationApprovalStagePaidSeed application={application} />
    case 'impact':
      return <ApplicationApprovalStageImpact application={application} />
    case 'paidImpact':
      return <ApplicationApprovalStagePaidImpact application={application} />
    default:
      return null
  }
}

export function ApplicationApproval({ application, stage }: Props) {
  return (
    <div className="flex justify-between">
      <StageComponent stage={stage} application={application} />
    </div>
  )
}
