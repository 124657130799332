import { FundApplication } from '../../types'
import Phase from './phase'

type TProps = {
  data: FundApplication
}

const ApplicationDetailsSteps = ({ data }: TProps) => {
  return (
    <div data-testid="application-detail-steps" className="space-y-4">
      <span className="text-sm uppercase text-gray-700 opacity-70">Steps</span>
      {data.phases.map((phase) => (
        <Phase key={phase.id} phase={phase} locationId={data.location.id!} />
      ))}
    </div>
  )
}

export default ApplicationDetailsSteps
