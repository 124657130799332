import { TPhase, PhaseStatus, StepStatus } from 'features/funds/types'
import { findLast } from './es2023'

/**
 * Phases can be incomplete, complete, or locked.
 * If there is an incomplete phase, that is the current phase.
 * If there are no incomplete phases, the last complete phase is the current phase.
 * There should never be only locked phases in real scenarios, but in generated test data, this is possible.
 *
 * @returns the first incomplete phase, or the last complete phase if there are no incomplete phases
 */
export const getCurrentPhase = (phases: TPhase[]) => {
  if (phases.length === 0) {
    throw new Error('Cannot get current phase from empty phases array')
  }

  return (
    phases.find((phase) => phase.status === PhaseStatus.Incomplete) ||
    findLast(phases, (phase) => phase.status === PhaseStatus.Complete) ||
    phases[phases.length - 1]
  )
}

/**
 * @returns the first incomplete step in the phase, or the last step if there are no incomplete steps
 */
export const getCurrentStep = (phase: TPhase) => {
  const steps = phase.steps

  if (steps.length === 0) {
    throw new Error('Cannot get current step from empty steps array')
  }

  return (
    steps.find((s) => s.status === StepStatus.Incomplete) ||
    steps[steps.length - 1]
  )
}
