import { Skeleton, FunnelChart } from '@changex/design-system'
import { useApplicationAnalyticsQuery } from '@features/funds/hooks/use-application-analytics-query'
import { FundWithAggregates } from '@types'
import { TFundApplicationStatus } from 'features/funds/types'
import { utils } from 'features/funds/utils'
import ApplicationsPerIdea from './applications-per-idea'
import ApplicationsOverTime from './applications-over-time'
import SourcesOfApplications from './sources-of-applications'
import { Solution } from '@types'
import {
  TApplicationsInWaitlist,
  TApplicationsPerIdea,
} from 'shared/types/application-analytics.type'
import ExpandCollapse from 'components/expand-collapse'
import { useOpenGrantDraftApplicationList } from '@features/funds/hooks/use-open-grant-draft-application-list'
import { Link } from 'react-router-dom'

const LoadingSkeleton = () => {
  return (
    <section className="bg-copper-50 py-8">
      <div className="max-w-changex relative mx-auto">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl text-teal-900">Applications</h1>
          <Skeleton className="h-[444px] w-[1170px]" />
          <Skeleton className="h-[444px] w-[1170px]" />
        </div>
      </div>
    </section>
  )
}

type TProps = {
  fund: FundWithAggregates
}

const applicationStagesToFunnelSections = (waitlistTotal, perIdeaData) => {
  const totalApplications = perIdeaData.reduce(
    (acc, { total }) => acc + total,
    0
  )
  const fundedApplications = perIdeaData.reduce((acc, { funded }) => {
    return acc + funded
  }, 0)
  const inFundApplications =
    perIdeaData.reduce((acc, { inProgress }) => acc + inProgress, 0) +
    fundedApplications
  return [
    {
      label: 'Total applications',
      helperText: `${waitlistTotal} on waitlist`,
      value: totalApplications,
      tooltip:
        '<strong>Total applications</strong><p>All applications received for this fund regardless of status. This total includes the waitlist.</p><p>The separate figure shown for the waitlist refers to replication applications only.',
    },
    {
      label: 'In fund',
      value: inFundApplications,
      tooltip:
        '<strong>In fund</strong><p>Applications which were accepted into the fund (either automatically or manually).</p><br><p>This does not include applications which were later rejected or failed.</p>',
    },
    {
      label: 'Funded',
      value: fundedApplications,
      tooltip:
        '<strong>Funded</strong><p>Applications which have received funding.</p><br><p>Replications: paid seed, impact, paid impact, failed impact<br>Open Grants: allocated, approved, suceeded, paid seed, impact, paid impact, failed impact</p>',
    },
  ]
}

const applicationsPerIdeaData = (
  solutions: Solution[],
  applicationsInWaitlist: TApplicationsInWaitlist,
  applicationsPerIdea: TApplicationsPerIdea
) => {
  return solutions.map((solution) => {
    const applicationsPerStage = applicationsPerIdea[solution.id] || {}
    const {
      preAllocated = 0,
      allocated = 0,
      approved = 0,
      succeeded = 0,
      paidSeed = 0,
      impact = 0,
      paidImpact = 0,
      failedImpact = 0,
      failed = 0,
      rejected = 0,
    } = applicationsPerStage
    const onWaitlist = applicationsInWaitlist[solution.id] ?? 0
    const waiting =
      solution.id === '99' ? preAllocated + onWaitlist : onWaitlist
    const total =
      Object.entries(applicationsPerStage).reduce(
        (total, [stage, subtotal]) => {
          if (stage === TFundApplicationStatus.unfunded) return total
          return total + subtotal
        },
        0
      ) + onWaitlist
    const inProgress =
      (solution.id !== '99' ? preAllocated : 0) +
      (solution.id !== '99' ? allocated : 0) +
      (solution.id !== '99' ? approved : 0) +
      (solution.id !== '99' ? succeeded : 0)
    const funded =
      (solution.id === '99' ? allocated : 0) +
      (solution.id === '99' ? approved : 0) +
      (solution.id === '99' ? succeeded : 0) +
      paidSeed +
      impact +
      paidImpact +
      failedImpact
    const unsuccessful = failed + rejected
    return {
      solution,
      total,
      waiting,
      inProgress,
      funded,
      unsuccessful,
    }
  })
}

const ApplicationsFunnel = ({
  waitlistTotal,
  perIdeaData,
  draftApplicationsCount,
  fundId,
}) => {
  const data = applicationStagesToFunnelSections(waitlistTotal, perIdeaData)
  return (
    <div className="w-full rounded bg-white p-4">
      <FunnelChart
        data={data}
        noDataInfo={{
          title: 'No applications yet',
          description: 'Come back later',
        }}
      />
      {draftApplicationsCount && (
        <Link
          className="mt-5 block text-sm text-gray-500 underline underline-offset-2"
          to={`/funds/${fundId}/draft-applications`}
          relative="path"
        >
          + {draftApplicationsCount} draft grant application
          {draftApplicationsCount > 1 && 's'}
        </Link>
      )}
    </div>
  )
}

const DashboardApplications = ({ fund }: TProps) => {
  const {
    isLoading,
    error,
    data: applicationAnalyticsData,
  } = useApplicationAnalyticsQuery(fund.id)
  const applicationAnalytics = applicationAnalyticsData?.results

  const { data: draftApplicationsData } = useOpenGrantDraftApplicationList(
    fund.id
  )

  if (isLoading) {
    return <LoadingSkeleton />
  }

  if (!!error) {
    // TODO
    return <div>Error loading applications analytics</div>
  }

  const waitlistTotal = applicationAnalytics?.applicationsInWaitlist
    ? utils.sumValues(applicationAnalytics.applicationsInWaitlist)
    : 0

  const perIdeaData = applicationsPerIdeaData(
    fund.solutions,
    applicationAnalytics?.applicationsInWaitlist || {},
    applicationAnalytics?.applicationsPerIdea || {}
  )
  return (
    <section className="bg-copper-50 py-8">
      <div className="max-w-changex relative mx-auto">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl text-teal-900">Applications</h1>
          <ApplicationsFunnel
            waitlistTotal={waitlistTotal}
            perIdeaData={perIdeaData}
            draftApplicationsCount={
              draftApplicationsData?.meta?.stats?.total?.count
            }
            fundId={fund.id}
          />
          <ApplicationsPerIdea data={perIdeaData} />
          <ExpandCollapse className="flex flex-col gap-6">
            <div className="grid grid-cols-2 gap-6">
              <ApplicationsOverTime
                data={applicationAnalytics?.applicationsOverTime || {}}
              />
              <SourcesOfApplications
                data={applicationAnalytics?.applicationSources || {}}
              />
            </div>
          </ExpandCollapse>
        </div>
      </div>
    </section>
  )
}

export default DashboardApplications
