import { useController, useFormContext } from 'react-hook-form'
import {
  MoneyInput,
  ExchangeRateInput,
  WithTooltip,
  Icon,
  BASE_CURRENCIES,
  Skeleton,
  Label,
  TCurrency,
} from '@changex/design-system'
import { useState } from 'react'

const TOOLTIPS = {
  convertedAmounts: `Please enter the USD or EUR amount that the fund has been contracted in.
  If it's contracted in USD please also include the EUR equivalent based on the exchange rate that the Finance team has decided on,
  and vice versa. This amount should not change once the fund is launched unless there is a top-up to the fund amount.
  These amounts are used for reporting.`,
  totalFunding: `This is the total amount of funding to be distributed under this fund in the currency it will be distributed in.
                 This amount should not change unless there is a top-up to the fund amount.`,
}

type Props = {
  currencies: TCurrency[] | undefined
}

const AmountEditor = ({ currencies }: Props) => {
  const { getValues, control } = useFormContext()
  const { field: currencyField } = useController({
    name: 'options.currency',
    rules: { required: true },
    control,
  })
  const { field: localAmountField } = useController({
    name: 'options.amount',
    rules: { required: true },
    control,
  })
  const { field: amountEurField } = useController({
    name: 'options.amountEur',
    rules: { required: true },
    control,
  })
  const { field: amountUsdField } = useController({
    name: 'options.amountUsd',
    rules: { required: true },
    control,
  })
  const state = getValues('state')

  const [eurRate, setEurRate] = useState(
    amountEurField.value / localAmountField.value || 1
  )
  const [usdRate, setUsdRate] = useState(
    amountUsdField.value / localAmountField.value || 1
  )

  const handleEurChange = (amount?: number) => {
    amountEurField.onChange(amount)
    setEurRate(amount ? amount / localAmountField.value : 0)
  }

  const handleUsdChange = (amount?: number) => {
    amountUsdField.onChange(amount)
    setUsdRate(amount ? amount / localAmountField.value : 0)
  }

  if (!currencies) {
    return <Skeleton className="h-[86px]" />
  }

  return (
    <div className="flex gap-4">
      <div className="w-2/4 border-r-2 pr-4">
        <div className="mb-1 flex gap-2">
          <Label htmlFor={localAmountField.name}>Total funding</Label>
          <WithTooltip tooltip={TOOLTIPS['totalFunding']}>
            <div className="mt-1">
              <Icon
                icon="QuestionMarkCircle"
                size="xs"
                className="text-gray-400"
              />
            </div>
          </WithTooltip>
        </div>
        <div>
          <MoneyInput
            canChangeCurrency={state !== 'published'}
            currencies={currencies}
            currency={
              currencies.find((c) => c.isoCode === currencyField.value)!
            }
            onCurrencyChange={(currency) => {
              currencyField.onChange(currency)
              if (currency === 'EUR') {
                handleEurChange(localAmountField.value)
              } else if (currency === 'USD') {
                handleUsdChange(localAmountField.value)
              }
            }}
            currencyElementId={currencyField.name}
            amount={localAmountField.value}
            amountElementId={localAmountField.name}
            onAmountChange={(amount) => {
              localAmountField.onChange(amount)
              amountEurField.onChange(Math.round(amount ? amount * eurRate : 0))
              amountUsdField.onChange(Math.round(amount ? amount * usdRate : 0))
            }}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-2">
          <div className="mb-1 block text-sm font-medium text-gray-700">
            Converted amounts
          </div>
          <WithTooltip tooltip={TOOLTIPS['convertedAmounts']}>
            <div className="mt-1">
              <Icon
                icon="QuestionMarkCircle"
                size="xs"
                className="text-gray-400"
              />
            </div>
          </WithTooltip>
        </div>
        <div className="mt-1 flex gap-4">
          <div className="w-1/2">
            <Label htmlFor={amountEurField.name} srOnly>
              EUR equivalent
            </Label>
            <ExchangeRateInput
              currency={BASE_CURRENCIES['eur']}
              amount={amountEurField.value}
              onAmountChange={handleEurChange}
              amountElementId={amountEurField.name}
              exchangeRate={eurRate}
              disabled={currencyField.value === 'EUR'}
            />
          </div>
          <div className="w-1/2">
            <Label htmlFor={amountUsdField.name} srOnly>
              USD equivalent
            </Label>
            <ExchangeRateInput
              currency={BASE_CURRENCIES['usd']}
              amount={amountUsdField.value}
              onAmountChange={handleUsdChange}
              amountElementId={amountUsdField.name}
              exchangeRate={usdRate}
              disabled={currencyField.value === 'USD'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AmountEditor
