import { FinancialAnalytics } from '../../../shared/types'
import { useJsonApiQuery } from '../../../shared/hooks/json-api/use-json-api-query'
import { TCurrencyIsoCode } from '@changex/design-system'

export function useFinancialAnalyticsQuery(
  id: string,
  currency: TCurrencyIsoCode
) {
  return useJsonApiQuery<FinancialAnalytics>([
    'analytics/funds/financials',
    {
      filter: {
        id,
        currency,
      },
      options: ['amount'],
    },
  ])
}
