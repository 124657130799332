import { Badge } from '@changex/design-system'
import {
  TMemberableType,
  TMembershipRole,
} from '@features/funds/hooks/use-membership-query'

type TProps = {
  role: TMembershipRole
  type: TMemberableType
}

const getBadgeVariant = (role: TMembershipRole, type: TMemberableType) => {
  if (type === TMemberableType.Fund && role === TMembershipRole.Admin) {
    return 'info'
  } else if (
    type === TMemberableType.Fund &&
    role === TMembershipRole.Regular
  ) {
    return 'basic'
  } else if (
    type === TMemberableType.Organisation &&
    role === TMembershipRole.Admin
  ) {
    return 'open_grant'
  } else if (
    type === TMemberableType.Organisation &&
    role === TMembershipRole.Regular
  ) {
    return 'purple'
  }
}

const RoleBadge = ({ role, type }: TProps) => {
  return (
    <Badge variant={getBadgeVariant(role, type)} className="text-base">
      {type} {role === TMembershipRole.Regular ? 'viewer' : role}
    </Badge>
  )
}

export default RoleBadge
