import classNames from 'classnames'
import {
  formatCurrency,
  formatDateShort,
  FundType,
  TCurrencyIsoCode,
  Tooltip,
  useHover,
} from '@changex/design-system'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'

type TProps = {
  label: string
  tooltip: string
  currency: TCurrencyIsoCode
  amount: number
  isLive: boolean
  startDate: Date
  endDate: Date
  fundType: FundType
}

const StatusBadge = ({ isLive }) => {
  return (
    <span className="absolute right-10 top-8 flex items-center gap-3 text-gray-600">
      <span className="relative flex h-3 w-3">
        <span
          className={classNames({
            'absolute inline-flex h-full w-full rounded-full opacity-75': true,
            'animate-ping bg-green-500': isLive,
            'bg-gray-400': !isLive,
          })}
        />
        <span
          className={classNames({
            'relative inline-flex h-3 w-3 rounded-full': true,
            'bg-green-500': isLive,
            'bg-gray-400': !isLive,
          })}
        />
      </span>
      {isLive ? 'Live' : 'Inactive'}
    </span>
  )
}

const FundSummary = ({
  amount,
  tooltip,
  currency,
  label,
  isLive,
  startDate,
  endDate,
  fundType,
}: TProps) => {
  const [ref, isHovered] = useHover()

  return (
    <div
      className="relative w-fit rounded-md bg-gray-50 px-8 py-10"
      role="contentinfo"
      ref={ref}
    >
      <StatusBadge isLive={isLive} />
      <h1 className="flex items-center gap-2 text-lg text-gray-800">
        {label}
        {isHovered && (
          <Tooltip>
            <Tooltip.Trigger>
              <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
            </Tooltip.Trigger>
            <Tooltip.Content>{tooltip}</Tooltip.Content>
          </Tooltip>
        )}
      </h1>
      <h2 className="text-3xl text-teal-900">
        {formatCurrency(amount, currency)}
      </h2>

      <div className="mt-9 flex w-96 flex-col">
        <span className="text-gray-600">
          Launched on <b>{formatDateShort(startDate)}</b>
        </span>
        {(fundType === FundType.Hybrid || fundType === FundType.Open) && (
          <span className="text-gray-600">
            Open applications close on <b>{formatDateShort(endDate)}</b>
          </span>
        )}
      </div>
    </div>
  )
}

export default FundSummary
