import { httpClient } from '@api'

type TInput = {
  comment: string
  commentable_id: string
  type: 'posts'
}

export function useCreatePostCommentMutation() {
  return {
    mutate: async (input: TInput) => {
      return await httpClient.post('/comments', {
        data: {
          type: 'comments',
          attributes: {
            comment: input.comment,
          },
          relationships: {
            commentable: {
              data: {
                id: input.commentable_id,
                type: input.type,
              },
            },
          },
        },
      })
    },
  }
}
