import { Fragment } from 'react'
import logo from './../../assets/logo.png'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { User } from '@types'
import { useCurrentUser } from '../../shared/providers/current-user-provider'
import { CxMenuDropdown, SearchBar } from '@changex/design-system'

const CHANGEX_ADDRESS = process.env.REACT_APP_CHANGEX_ADDRESS

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {
  const user = useCurrentUser()

  return (
    <header className="h-header z-30 flex items-center bg-white px-8 shadow-sm">
      <div className="max-w-changex mx-auto w-full">
        <div className="flex justify-between">
          <div className="flex items-center">
            <a href={CHANGEX_ADDRESS} className="flex items-center">
              <img
                src={logo}
                className="flex"
                alt="ChangeX logo"
                data-testid="logo"
                height={31}
                width={130}
              />
            </a>
            <div className="ml-10">
              <SearchBar />
            </div>
          </div>
          <div className="user flex items-center gap-4">
            <CxMenuDropdown />
            {user ? <UserMenu {...user} /> : null}
          </div>
        </div>
      </div>
    </header>
  )
}

const UserMenu = ({
  firstName,
  hasIdeas = false,
  hasFunds = false,
}: Partial<User>) => {
  const userNavigation = [
    { name: 'Profile settings', href: `/account` },
    hasFunds ? { name: 'My funds', href: `/home` } : null,
    hasIdeas ? { name: 'My ideas', href: `/home` } : null,
    { name: 'Notifications', href: `/notifications` },
    { name: 'Log out', href: `/users/sign_out` },
  ]

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="text-md flex max-w-xs items-center rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2">
          <span className="sr-only">Open user menu</span>
          <div className="flex items-center">
            <div>
              <p className=" group-hover:text-gray-900">{firstName}</p>
            </div>
            <div className="ml-0.5">
              <ChevronDownIcon className="h-5 w-5 text-gray-500" />
            </div>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation
            .filter((n) => n)
            .map((item) => (
              <Menu.Item key={item?.name}>
                {({ active }) => (
                  <a
                    href={(CHANGEX_ADDRESS as string) + item?.href}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'text-md block px-4 py-2 text-gray-700'
                    )}
                  >
                    {item?.name}
                  </a>
                )}
              </Menu.Item>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Header
