import {
  formatCurrency,
  FundType,
  TCurrencyIsoCode,
} from '@changex/design-system'
import { utils } from 'features/funds/utils'
import classnames from 'classnames'

type TProps = {
  currency: TCurrencyIsoCode
  fundTotalAmount: number
  fundType: FundType
  requestedFunds: { [key: string]: number }
  waitingFunds: { [key: string]: number }
}

type TSimpleStatProps = {
  label: string
  value: number
  currency: TCurrencyIsoCode
  variant?: 'light' | 'dark'
  percentageRatio?: number
}

const SimpleStat = ({
  label,
  value,
  currency,
  variant = 'light',
  percentageRatio,
}: TSimpleStatProps) => {
  return (
    <div
      className={classnames('flex flex-1 flex-col px-8 py-6', {
        'bg-gray-50': variant === 'dark',
      })}
    >
      <div className="mb-1 text-lg text-gray-800">{label}</div>
      <div>
        <span className="text-2xl text-teal-800">
          {formatCurrency(value, currency)}
        </span>
        {percentageRatio !== undefined && (
          <span className="ml-1 text-base text-gray-500">
            (
            {new Intl.NumberFormat('en-GB', { style: 'percent' }).format(
              percentageRatio!
            )}
            )
          </span>
        )}
      </div>
    </div>
  )
}

const NonHybridStats = ({
  currency,
  fundTotalAmount,
  requestedFunds,
  waitingFunds,
  fundType,
}: TProps) => {
  const totalRequested = utils.sumValues(requestedFunds)
  const totalWaiting = utils.sumValues(waitingFunds)
  return (
    <div className="flex rounded-md border border-gray-100">
      <SimpleStat
        label="Total requested"
        currency={currency}
        value={totalRequested}
        percentageRatio={totalRequested / fundTotalAmount}
      />
      <SimpleStat
        label={fundType === FundType.Open ? 'Inbox' : 'Waitlist'}
        currency={currency}
        value={totalWaiting}
        variant="dark"
      />
    </div>
  )
}

export default NonHybridStats
