import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { ReactNode, useState } from 'react'

const ExpandCollapse = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const Icon = isExpanded ? ChevronUpIcon : ChevronDownIcon
  return (
    <div className={className}>
      {isExpanded && <>{children}</>}
      <button
        className="flex w-fit items-center self-end rounded-lg border border-teal-800 p-2 text-teal-800"
        onClick={() => setExpanded(!isExpanded)}
      >
        {isExpanded ? 'Show less' : 'Show more'}
        <Icon className="h-5 w-5 text-teal-800" />
      </button>
    </div>
  )
}

export default ExpandCollapse
