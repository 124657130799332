import { useApplicationBulkRejectMutation } from '@features/funds/hooks/use-application-bulk-reject-mutation'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { APPLICATION_APPROVAL_TOAST_DURATION } from '@features/funds/components/applications-list/application/approval/application-approval.constants'
import { Fund } from '@types'

export function useBulkRejectApplications(fund: Fund) {
  const bulkRejectApplicationsMutation = useApplicationBulkRejectMutation()

  const mutate = useCallback(() => {
    bulkRejectApplicationsMutation.mutate(
      {
        fund_id: fund.id!,
      },
      {
        onSuccess: () => {
          toast.success(
            'Bulk rejection started. You should see all applications rejected within 5 mins',
            {
              duration: APPLICATION_APPROVAL_TOAST_DURATION,
            }
          )
        },
        onError: () => {
          toast.error(
            'There was an error rejecting open-grant inbox applications',
            {
              duration: APPLICATION_APPROVAL_TOAST_DURATION,
            }
          )
        },
      }
    )
  }, [bulkRejectApplicationsMutation, fund])

  return {
    mutate,
    isLoading: bulkRejectApplicationsMutation.isLoading,
  }
}
