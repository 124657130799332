import { useJsonApiMutation } from 'shared/hooks/json-api/use-json-api-mutation'
import { useJsonApiConfig } from 'shared/hooks/json-api/json-api-provider'
import { Fund } from 'shared/types'
import { useForm, FormProvider } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import {
  Button,
  Divider,
  FormSection,
  LoadingSpinner,
  UnsavedChangesBadge,
} from '@changex/design-system'
import BasicDetailsEditor from './basic-details-editor'
import PortfolioEditor from './portfolio-editor'
import OpenGrantEditor from './open-grant-editor'
import LocationEditor from './location-editor'
import toast from 'react-hot-toast'
import { useCallback } from 'react'
import { useSettings } from 'shared/providers'
import { useSettingMutation } from '@features/funds/hooks/use-setting-mutation'
import { useCurrenciesQuery } from '@features/funds/hooks/use-currencies-query'

type FundEditorProps = {
  id: string
  fund: Fund
}

type SaveButtonProps = {
  onSave: () => void
  isSaving: boolean
}

const EDITABLE_SETTINGS = ['impact_waiting_period', 'nudge_emails_enabled']

const SaveButton = ({ onSave, isSaving }: SaveButtonProps) => {
  if (isSaving) {
    return (
      <Button disabled={true}>
        <LoadingSpinner />
        <span>Saving...</span>
      </Button>
    )
  }

  return <Button onClick={onSave}>Save</Button>
}

function useFundMutation() {
  return useJsonApiMutation<Fund>('funds', false)
}

const FundEditor = ({ id, fund }: FundEditorProps) => {
  const { solutions: _, ...fundDetails } = fund // TODO: maybe exclude solutions at app-level
  const formMethods = useForm({ defaultValues: fundDetails })
  const { control, watch, reset, handleSubmit } = formMethods
  const grantType = watch('options.type')

  const { serializer } = useJsonApiConfig()
  const fundMutation = useFundMutation()
  const settingMutation = useSettingMutation()
  const settings = useSettings()
  const currencies = useCurrenciesQuery()

  const onSubmit = useCallback(
    (data) => {
      fundMutation.mutate(data, {
        onSuccess: (response) => {
          reset(serializer.deserialize(response).results)
          toast.success('Saved!')
        },
        onError: (error, variables) => {
          toast.error('Oops! Something went wrong')
          console.log('error', error, variables)
        },
      })

      for (const setting of settings) {
        if (!EDITABLE_SETTINGS.includes(setting.key)) {
          continue
        }

        settingMutation.mutate(setting, {
          onError: (error, variables) => {
            toast.error('Oops! Something went wrong')
            console.log('error', error, variables)
          },
        })
      }
    },
    [fundMutation, reset, serializer, settings, settingMutation]
  )

  return (
    <FormProvider {...formMethods}>
      <div className="sticky top-[48px] z-10 border-b border-b-gray-100 bg-white py-3 px-6">
        <div className="flex justify-between">
          <h1 className="text-2xl">General</h1>
          <div className="flex gap-2">
            <UnsavedChangesBadge />
            <SaveButton
              onSave={handleSubmit(onSubmit)}
              isSaving={fundMutation.isLoading || settingMutation.isLoading}
            />
          </div>
        </div>
        {fund.internalIdentifier && (
          <h2 className="text-sm text-gray-600">{fund.internalIdentifier}</h2>
        )}
      </div>

      <div className="p-6">
        <FormSection
          title="Basic details"
          subtitle="These settings might affect the Terms and Conditions so make sure to update them too."
        >
          <BasicDetailsEditor currencies={currencies.data?.results} />
        </FormSection>
        {grantType !== 'open' && (
          <>
            <Divider />
            <FormSection
              title="Portfolio"
              subtitle="Be careful making changes to live Funds - don't remove or change the allocation amounts for an Idea that already has applications. You can change the replication limit without issue."
            >
              <PortfolioEditor />
            </FormSection>
          </>
        )}
        {grantType !== 'replication' && (
          <>
            <Divider />
            <FormSection title="Open-grants">
              <OpenGrantEditor
                currency={currencies.data?.results.find(
                  (i) => i.isoCode === fund.options.currency
                )}
              />
            </FormSection>
          </>
        )}
        <Divider />
        <FormSection title="Catchment area">
          <LocationEditor />
        </FormSection>
      </div>
      {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
    </FormProvider>
  )
}

export default FundEditor
