import marker from '../../../../assets/marker.png'
import openGrantMarker from '../../../../assets/open-grant-marker.png'
import classNames from 'classnames'

export type MarkerPointerProps = {
  openGrant: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export type ClusterCircleMarkerSize = 'small' | 'medium' | 'large'

export type ClusterCircleMarkerProps = {
  text?: string | number
  size: ClusterCircleMarkerSize
  diameter?: string | number
  handleClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  hoverCursor?: boolean
}

export const Marker = ({ children, lat, lng, className = '' }) => children

export const MarkerPointer = ({ openGrant, onClick }: MarkerPointerProps) => {
  return (
    <img
      className="cursor-pointer transition duration-200 hover:-translate-y-2 hover:scale-110"
      src={openGrant ? openGrantMarker : marker}
      alt="marker"
      width={openGrant ? 29 : 23}
      onClick={onClick}
    />
  )
}

export const ClusterCircleMarker = ({
  text,
  size,
  diameter,
  handleClick,
  hoverCursor,
}: ClusterCircleMarkerProps) => {
  let circleDiameter: string | number
  let circleFillColor: string | number
  switch (size) {
    case 'small':
      circleDiameter = diameter ? diameter : '8'
      circleFillColor = 'bg-[#2C5CEB]'
      break
    case 'medium':
      circleDiameter = diameter ? diameter : '10'
      circleFillColor = 'bg-[#FB9304]'
      break
    case 'large':
      circleDiameter = diameter ? diameter : '11'
      circleFillColor = 'bg-[#F31C1C]'
      break
  }
  return (
    <div
      onClick={handleClick}
      className={classNames(
        `relative flex h-${circleDiameter} w-${circleDiameter} items-center justify-center`,
        { 'cursor-pointer': hoverCursor }
      )}
    >
      <div
        className={`${circleFillColor} absolute inset-0 scale-150 transform rounded-full opacity-20`}
      ></div>
      <div
        className={`${circleFillColor} absolute inset-0 flex items-center justify-center rounded-full`}
      >
        {text && <p className="text-sm text-white">{text}</p>}
      </div>
    </div>
  )
}
