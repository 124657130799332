import {
  ButtonGroup,
  TCurrency,
  TCurrencyIsoCode,
} from '@changex/design-system'

type TProps = {
  options: TCurrency[]
  value: TCurrencyIsoCode
  onChange: (value: TCurrencyIsoCode) => void
}

const CurrencyFilter = ({ options, value, onChange }: TProps) => {
  return (
    <ButtonGroup
      items={options.map((currency) => ({
        label: `${currency.symbol} ${currency.isoCode}`,
        onClick: () => onChange(currency.isoCode),
        active: value === currency.isoCode,
      }))}
    />
  )
}

export default CurrencyFilter
